/* eslint-disable react-hooks/exhaustive-deps */
'use client'

import { useCallback, useMemo } from 'react'
import { usePathname } from 'next/navigation'
import { UrlModel } from '@/types'
import useMobileOS from '@/hooks/use-mobile-os'
import { ButtonGroupVariantProps, buttonGroup } from './button-group.variants'

export type useButtonGroupProps = ButtonGroupVariantProps & {
  className?: string
  sectionName: string
  title?: string | null
  buttons?: UrlModel[]
  hideStoresOnDesktop?: boolean
}

const ignorePaths = ['/beat-friday-2023/']

const OSRestrictions = {
  ['apps.apple.com']: 'android',
  ['play.google.com']: 'ios',
  ['desktop.moises.ai']: ['ios', 'android'],
  ['signup']: ['ios', 'android']
}

export const useButtonGroup = (props: useButtonGroupProps) => {
  const {
    className = '',
    sectionName,
    title,
    buttons = [],
    theme = 'dark',
    align = 'left',
    spacing = 'md',
    hideOnMobile = false,
    hideStoresOnDesktop = true,
    ...componentProps
  } = props

  const pathname = usePathname()
  const { mobileOS } = useMobileOS()

  const styles = useMemo(
    () =>
      buttonGroup({
        className,
        theme,
        align,
        spacing,
        hideOnMobile,
        show: mobileOS !== false
      }),
    [className, theme, align, spacing, hideOnMobile, mobileOS]
  )

  const ignoreHideButton = useMemo(() => {
    return ignorePaths?.includes(pathname)
  }, [pathname])

  const shouldHideUrl = useCallback(
    (url: string) => {
      if (ignoreHideButton) {
        return false
      }

      for (const [key, restrictedOS] of Object.entries(OSRestrictions)) {
        if (url.includes(key)) {
          if (Array.isArray(restrictedOS)) {
            if (mobileOS && restrictedOS.includes(mobileOS)) {
              return true
            }
          } else if (
            mobileOS === restrictedOS ||
            (!mobileOS && hideStoresOnDesktop)
          ) {
            return true
          }
        }
      }

      return false
    },
    [hideStoresOnDesktop, mobileOS, pathname, ignoreHideButton]
  )

  return {
    styles,
    className,
    sectionName,
    theme,
    title,
    buttons,
    shouldHideUrl,
    componentProps
  }
}
